import { ReactNode, useEffect, useState } from 'react';
import { AutoComplete, Button, Col, FloatButton, message, Popconfirm, Row, Segmented, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { User } from '../../store/types';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { AuthRoutes } from '../../components';
import { usersSelectors } from '../../store/selectors';
import { useStore } from 'react-redux';
import { networksSelectors } from '../../store/selectors/network';
import { Network } from '../../store/types/network';
import { deleteUser } from '../../store/api';
import { users as usersReducer } from '../../store/reducers';
import { baseStyles } from '../../assets/styles';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { includes } from '../../utils/util-custom-validators';
import CheckUnchecked from '../main/check-uncheck';

const { Text } = Typography;
const key = 'saving';

const FormConfigurationUserIndex = () => {
	const store = useStore();
	const styles = baseStyles;
	const { t } = useTranslation();
	const letras = Array.from({ length: 26 }, (_, i) => String.fromCharCode(i + 97));
	const navigate = useNavigate();
	const [users, setUsers] = useState<User[]>([]);
	const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
	const [networks, setNeworks] = useState<Network[]>([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		var items = usersSelectors.selectAll(store.getState() as any).sort((a, b) => a.username.localeCompare(b.username));
		setUsers(items);
		setFilteredUsers(items.filter(u => u.username.startsWith('a')));
		setNeworks(networksSelectors.selectAll(store.getState() as any));
	}, [store, t, users.length, networks]);

	const columns: ColumnProps<User>[] = [
		{
			title: t('configurations.users'),
			dataIndex: 'username',
			key: 'username',
			sorter: {
				compare: (a: User, b: User) => {
					if (a.username < b.username) return -1;
					if (a.username > b.username) return 1;
					return 0;
				},
			},
		},
		{
			title: t('configurations.mobileLogins'),
			dataIndex: 'mobileLogins',
			key: 'mobileLogins',
			sorter: {
				compare: (a: User, b: User) => {
					return b.mobileLogins - a.mobileLogins;
				},
			},
		},
		{
			title: t('configurations.webLogins'),
			dataIndex: 'webLogins',
			key: 'webLogins',
			sorter: {
				compare: (a: User, b: User) => {
					return b.webLogins - a.webLogins;
				},
			},
		},
		{
			title: t('professionals.isActive'),
			dataIndex: 'isDeleted',
			key: 'isDeleted',
			render: (text: string, item: User): ReactNode => {
				return <CheckUnchecked isChecked={!item.isDeleted}></CheckUnchecked>;
			},
		},
		{
			title: t('general.actions'),
			dataIndex: 'edit',
			key: 'edit',
			width: 100,
			render: (_text: string, item: User): ReactNode => {
				return (
					<Space size="middle">
						<Link to={AuthRoutes.configuration + `/user`} state={{ id: item.id }}>
							<Tooltip title={t('general.edit')}>
								<Button style={styles.button} type="primary" icon={<EditOutlined />} shape="round" />
							</Tooltip>
						</Link>
						<Popconfirm
							title={t('messages.confirmDeleteItem')}
							onConfirm={() => {
								handleDeleteUser(item);
							}}
							okText={t('general.ok')}
							cancelText={t('general.cancel')}
						>
							<Tooltip title={t('general.delete')}>
								<Button icon={<DeleteOutlined />} danger shape="round" />
							</Tooltip>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const handleDeleteUser = async (item: User) => {
		message.loading({ content: t('messages.saving'), key, duration: 0 });
		var response = await deleteUser(item);
		if (response) {
			store.dispatch(usersReducer.actions.userCreateUpdate({ ...item, isDeleted: true }));
			setUsers(usersSelectors.selectAll(store.getState() as any).filter(u => !u.isDeleted));
			message.info({ content: t('messages.itemDeleted'), key, duration: 2 });
		} else message.info({ content: t('message.unableToDelete'), key, duration: 2 });
	};

	const handleSearch = (value: string) => {
		if (value) {
			var searchResult = usersSelectors.selectAll(store.getState() as any).filter(u => includes(u.username, value) && !u.isDeleted);
			var searchVisualization = searchResult.map(u => {
				return {
					value: u.id,
					label: <Text strong>{u.username}</Text>,
				};
			});
			setOptions(searchVisualization);
		} else setOptions([]);
	};

	const handleSelect = (_value, option) => {
		navigate(AuthRoutes.configuration + `/user`, { state: { id: option.value } });
	};

	return (
		<div>
			<Row>
				<Col flex={3}>
					<Space direction="horizontal">
						<Text strong>{t('general.searchEngine')}</Text>
						<AutoComplete
							popupMatchSelectWidth={300}
							style={styles.search}
							placeholder={t('general.search')}
							allowClear
							options={options}
							onSelect={handleSelect}
							onSearch={handleSearch}
						/>
						<Segmented<string>
							style={{ borderColor: 'red' }}
							onChange={value => setFilteredUsers(users.filter(u => u.username.startsWith(value)))}
							defaultValue={undefined}
							options={letras.map(val => {
								return { label: val.toUpperCase(), value: val };
							})}
						/>
					</Space>
				</Col>
				<Col flex={4} />
				<Col flex={3}>
					<Row justify="end">
						<Space size="middle">
							<Button
								type="primary"
								shape="round"
								icon={<PlusOutlined />}
								onClick={() => {
									navigate(AuthRoutes.configuration + `/user`, { state: { id: 0 } });
								}}
							>
								{t('general.add')}
							</Button>
						</Space>
					</Row>
				</Col>
			</Row>
			<Row style={{ paddingTop: 10 }}>
				<Col flex={1}>
					<FloatButton.BackTop key="back-top" />
					<Table<User> rowKey={item => item.id} tableLayout="auto" size="small" dataSource={filteredUsers} pagination={false} columns={columns} />
				</Col>
			</Row>
		</div>
	);
};

export default FormConfigurationUserIndex;
